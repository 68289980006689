/** Forms **/
.bs-form {
  width: 100%;
  max-width: 600px;
  min-width: 100px;
}

.bs-textarea {
  background-color: #f0f0f0;
  border-left: 2px solid #f0f0f0;
  overflow: scroll !important;
  /** Overwriting the ripple **/
  border-radius: 3px;
  padding: 3px;
  border-color: #f0f0f0;
  width: 300px;
  font-family: Segoe UI;
  height: 150px;
  -webkit-transition: all 300ms ease;
  /* Firefox */
  -moz-transition: all 300ms ease;
  /* WebKit */
  -o-transition: all 300ms ease;
  /* Opera */
  transition: all 300ms ease;
  /* Standard */
}
.bs-textarea:focus, .bs-textarea:hover {
  outline: none;
}

.bs-textfield-outer .show-border {
  border-left: 2px solid #1b387e !important;
}
.bs-textfield-outer .bs-textfield {
  background-color: #fafafa;
  border-left: 2px solid #fafafa;
}
.bs-textfield-outer .bs-textfield .bs-textfield-icon {
  color: #1b387e;
}
.bs-textfield-outer .bs-textfield:focus, .bs-textfield-outer .bs-textfield:hover {
  border-left: 2px solid #1b387e;
  background-color: white;
}
.bs-textfield-outer .bs-textfield input {
  color: rgba(0, 0, 0, 0.87);
}

.bs-search-field .bs-textfield {
  background-color: #e0e0e0 !important;
  border-left: 2px solid #e0e0e0 !important;
}

.bs-textarea {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.bs-textarea:focus, .bs-textarea:hover {
  border-left: 2px solid #1b387e;
  background-color: white;
}
