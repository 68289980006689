$hforange:#dc911b;
$hfblue:rgb(0, 8, 122);
$hfshadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.095);
$hfshadowHover: 1rem  1rem  5rem rgba(50, 50, 50, 0.379);
:root {
   font-size: 6.25%; /** Default browsers will have 16px, so this will be 10px now **/
}
body {
   min-height: 100vh; /** HTML will inherit this, lets html and body grow with its content **/
}
[hidden] { display: none !important;}
/** Main tags **/
html, body { 
   font-family: 'Barlow';
   font-size: 16rem; /** Equals 16px if the default font of 16px is applied **/
   line-height: 1.5em;
   letter-spacing: .5px;
   margin: 0;
   padding: 0;
   margin: 0;
   color: #00152b;
   /** In a narrow mobile view there is a strange white area on the right side if not putting this... 
       Needs more investigation. **/
   overflow-x: hidden;
}
.mmr-min-page-min-height {
   min-height: 500rem;
}
.hfmg-centered-header, .hfmg-main, .hfmg-footer, .hfmg-breadcrumb {
   width: 100%;
   max-width: 1350rem;
   margin: 0 auto;
}
* {
   box-sizing: border-box;
   /** Remove all click focus outlines**/
   outline: none;
}   
h1 {
   font-size: 30rem!important;
   margin: 0 0 0 0!important;
}
h2 {
   /** Overwrite material styles **/
   margin: 0 0 5px 0!important;
   color: #080064;
}
h3 {
   /** Overwrite material styles **/
   margin: 0 0 5px 0!important;
   color: #080064;
}
h4 {
   font-weight: bold!important;
   color: #000;
   font-size: 12px;
}
h5 {
   margin: 0 0 5px 0!important;
   font-weight: bold!important;
   text-transform: uppercase;
} 
ul {
   margin: 0;
   padding: 0;
   list-style: none;
}
li {
   margin: 0;
   padding: 0;
}
*::-webkit-scrollbar {
   width: 10px;
   height: 10px;
}
*::-webkit-scrollbar-track {
   background-color: rgba(255, 255, 255, 0.1);
}
*::-webkit-scrollbar-thumb {
   background-color: #ccc;
   -webkit-box-shadow: rgba(0,0,0,.12) 0 3px 13px 1px;
}
.bs-search input {
   font-size: 20px;
}
.clear-both {
   clear: both;
}
.float-left {
   float: left;
}
.mat-card-header mat-icon {
   width: auto!important;
   height: auto!important;
}
a {
   cursor: pointer;
   text-decoration: none;
   color: #003671;
   &:focus {
      outline: none!important;
      border: none;
   }
   &:hover {
      color: #dc911b;
   }
}
.mmr-text-content {
   margin: 10px;
}
.mmr-page-inner-hidden {
   display: none;
   height: 100%;
}
.mmr-page-inner-shown {
   height: 100%;
}
.mmr-highlighted-message {
   display: inline-block;
   padding: 5px 10px;
   margin: 10px;
   border: 1px solid #000054;
   color: #000;
   border-radius: 5px;
   background-color: #080065;
   color: #fff;
}
.jvectormap-label {
   position: absolute;
   display: none;
   border: solid 1px #CDCDCD;
   -webkit-border-radius: 3px;
   -moz-border-radius: 3px;
   border-radius: 3px;
   background: #292929;
   color: white;
   font-family: sans-serif, Verdana;
   font-size: smaller;
   padding: 3px;
   z-index:11;
}

.mmr-search-input {
   width: 100%;
}

/** Products **/
.mmr-product-values {
   display: flex;
   width: 100%;
   .mmr-product-label {
      flex-basis: 130px;
   }
   .mmr-product-value {
      flex-grow: 1;
      font-weight: bold;
   }
}

/** Colors **/

$primary-color: #003671;
$secondary-color: #ff9800;

.bs-page-loading {
   position: relative;
   margin-left: 60px;
   margin-top: 60px;
   .bs-page-loading-illustration {
      width: 300px;
   }
   .bs-page-loading-animation {
      position: absolute;
      left: 137px;
      top: 47px;
   }
}


/** Material **/
mat-icon {
   color: $primary-color!important;
}
mat-card {
   background-color: #e8e8e8!important;
   mat-card-header {
      mat-icon {
         font-size: 50px!important;
         height: 50rem!important;
         width: 50rem!important;         
      }
   }
}
.mat-raised-button {
   mat-icon {
      color: #fff;
   }
}

.mmr-delete-icon {
   color: red;
}
.mat-snack-bar-container {
   background-color: $primary-color!important;
}
.mat-card {
   background-color: #eeeeee !important
}
.mat-radio-button {
   margin-right: 16px;
}
/** Style comes from material - whyever.. */
.mat-card>:first-child, .mat-card-content>:first-child {
   margin-top: 10px!important;
}
/** Forms **/
.bs-form {
   width: 100%;
   max-width: 600px;
   min-width: 100px;
}
.bs-textarea {
   background-color: #fff;
   border-left: 2px solid #fff;
   overflow: scroll!important; /** Overwriting the ripple **/   
   border-radius: 3px;
   padding: 3px;
   border-color: #f0f0f0;
   width: 300px;
   height: 150px;
   &:focus, &:hover {
      outline: none;
   }
}

.mmr-flex-card {
   height: 100%;
   width: 100%;
   display: flex!important;
   flex-direction: column;
   mat-card-header {
      flex: 0 0 60px;
   }
   mat-card-content {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      .mmr-service-book-item-list-head {
         flex: 0 0 30px;
      }
      .mmr-service-book-item-list-body {
         flex: 1 0 auto;
      }
   }  
}

/* Ag grid **/
.ag-row-selected {
   background-color: rgb(193, 204, 252)!important;
}
.bs-property-error-message-outer {
   font-size: 11px;
}
.bs-property-error-message, .bs-error-message  {
   color: red;
}   

/** Buttons next to each other **/
.mmr-button-margin {
   margin-left: 10px!important;
}

/** Cards **/
.mmr-action-card {
   float: left;
   cursor: pointer;
   margin: 10px;
   &:hover {
      background-color: rgb(218, 218, 218)!important;
   }
   .mmr-action-card {
      mat-icon {
         color: $secondary-color;
      }
   }
}
.mmr-white-card {
   background-color: #fff!important;
}


/** Filters **/

.mmr-filter-inner {
   display: flex;
   flex-direction: column;
   height: 100%;
   .mmr-filter-head {
      .mmr-filter-optionblock {
         float: left;
         margin: 10px;
      }
      .mmr-document-filter {
         margin-top: -10px;
         .mmr-filter-label {
            font-size: 15px;
            font-weight: bold;
         }
      }
   }
   .mmr-documents {
      flex: 1 0 auto;
   }
   .mmr-service-book {
      flex: 1 0 auto;
   }
}
.mmr-service-book .ag-header-row-column-group {
   display: none;
}
.notification-message {
   display: inline-block;
   padding: 5px;
   background-color: #680000;
   color: #fff;
   font-size: 14px;
   border-radius: 3px;
}
/** Color indicators **/
.much-time-left {
   
}
.medium-time-left {
   background-color: rgb(255, 215, 104)!important;
}
.almost-over {
   background-color: #ffb3b3!important;
}
/** AG grid **/
.ag-header-cell-menu-button {
   display: none!important;
}
/** Material **/
.mat-menu-panel {
   width: 270rem!important;
}